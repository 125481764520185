@font-face {
  font-family: "Blender";
  font-style: normal;
  font-weight: 700;
  src:
    url(./blender/blender-bold.woff2) format("woff2"),
    url(./blender/blender-bold.woff) format("woff"),
    url(./blender/blender-bold.ttf) format("truetype");
}

@font-face {
  font-family: "Blender";
  font-style: normal;
  font-weight: 500;
  src:
    url(./blender/blender-medium.woff2) format("woff2"),
    url(./blender/blender-medium.woff) format("woff"),
    url(./blender/blender-medium.ttf) format("truetype");
}

@font-face {
  font-family: "Sohne";
  font-style: normal;
  font-weight: 900;
  src:
    url(./soehne/soehne-buch.woff2) format("woff2"),
    url(./soehne/soehne-buch.ttf) format("truetype");
}

@font-face {
  font-family: "Sohne";
  font-style: normal;
  font-weight: 500;
  src:
    url(./soehne/soehne-leicht.woff2) format("woff2"),
    url(./soehne/soehne-leicht.ttf) format("truetype");
}
