@import '#/styles/variables.scss';
@import '#/styles/mixins';

.nova-title {
  font-weight: 400;
  margin: #{$small-space} 0;

  &.big {
    font-size: 24px !important;
  }

  &.small {
    font-size: 12px !important;
  }
  &.medium-small {
    font-size: 14px !important;
  }

  &.dark {
    color: var(--header-primary-color-dark) !important;
  }

  &.clickable {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  &.nova-title-primary {
    font-size: var(--title-primary-size);
    color: var(--title-primary-color);
  }

  &.nova-title-secondary {
    font-size: var(--title-secondary-size);
    color: var(--title-secondary-color);
  }
}
