@import '../variables';

body.theme-dark {
  // marketing
  --marketing-bg-color-light: #{$light-theme-marketing-primary-bg-color};
  --marketing-bg-color-dark: #{$dark-theme-thirdy-background-color};
  --marketing-bg-logout: #{$dark-theme-thirdy-background-color};

  // trade
  --trade-small-card-bg: #{$dark-theme-thirdy-background-color};
  --trade-small-card-bg-border: 1px solid #ffffff1f;
  --trade-small-card-border-radiues: 8px;
  --trade-small-card-icon-holder-color: #{$light-theme-secondary-background-color};
  --trade-ui-bg: #{$dark-theme-secondary-background-color};

  // markets
  --markets-active-star-color: #{$dark-theme-markets-favorite-instrument-color-active};
  --markets-star-color: #{$dark-theme-markets-favorite-instrument-color};
  --markets-chart-color-positive: #{$light-theme-primary-positive-color};
  --markets-chart-color-negative: #{$light-theme-primary-negative-color};
  --markets-header-color: #{$light-theme-primary-header-text-color};
  --markets-item-color: #{$dark-theme-primary-elements-light-color};
  --markets-item-bg-color-active: #{$light-theme-primary-header-text-color};
  --markets-trade-button-border-color: #{$light-theme-primary-aside-bg};
  --markets-trade-button-bg-color: #{$light-theme-primary-aside-bg};
  --markets-trade-button-color: #{$light-theme-primary-regular-text-color};
  --markets-trade-main-button-color: #{$light-theme-trade-main-button-bg};
  --markets-trade-pair-selector-color: #{$light-theme-trade-color-pair-selector};
  --markets-trade-pair-color: #{$dark-theme-primary-light-text-color};
  --markets-trade-amount-color: #{$dark-theme-primary-light-text-color};
  --markets-trade-field-border-color: #{$light-theme-trade-field-border-color};
  --markets-trade-search-bg: #{$light-theme-trade-search-bg};
  --markets-trade-slider-bg: #{$light-theme-trade-slider-bg};
  --markets-trade-swap-bg: #{$light-theme-trade-slider-bg};
  --markets-trade-swap-exchange-bg: #{$light-theme-primary-elements-bg-color};

  // exchange
  --exchange-orderbook-positive: #{$light-theme-primary-positive-color};
  --exchange-orderbook-negative: #{$light-theme-primary-negative-color};
  --exchange-orderbook-text-positive: #{$light-theme-primary-positive-text-color};
  --exchange-orderbook-text-negative: #{$light-theme-primary-negative-text-color};
  --exchange-orderbook-divider-border-color: #{$light-theme-exchange-divider-border-color};
  --exchange-orders-row-text-color: #{$dark-theme-primary-light-text-color};
  --exchange-markets-negative: #{$light-theme-primary-negative-color};
  --exchange-markets-positive: #{$light-theme-primary-positive-color};
  --exchange-orders-row-hover: #{$dark-theme-exchange-order-book-row-hover};
  --exchange-trade-order-active-bg: #{$dark-theme-forty-background-color};
  --exchange-slider-bg: #{$dark-theme-slider-bg};
  --exchange-order-history-row-hover: rgba(73, 74, 104, 0.91);
  --exchange-order-history-filter-bg: #{$dark-theme-secondary-background-color};
  --exchange-order-slidebar-bg: #{$light-theme-primary-elements-dark-color};
  --exchange-order-slidebar-active-bg: #{$light-theme-primary-secondary-bg-color};

  // wallets
  --qr-code-bg: #{$dark-theme-secondary-background-color};
  --wallet-transaction-info-border: 1px solid #ffffff0f;
  --wallet-fund-info-border: 2px solid #ffffff0f;
  --wallet-transaction-info-header-bg: #{$dark-theme-secondary-background-color};

  // vaults
  --vaults-default-text-color: #{$dark-theme-primary-dark-text-color};
  --vaults-light-text: #{$dark-theme-vaults-light-text};
  --vaults-component-shadow: none;
  --vaults-component-shadow-active: none;
  --vaults-box-shadow-color: rgba(0, 0, 0, 0.4);
  --vaults-trade-border: 1px solid #{$dark-theme-vaults-trade-border-color};
  --vaults-trade-tab-active-bg: #{$dark-theme-element-color};
  --vaults-trade-tab-inactive-bg: #{$dark-theme-tab-inactive-bg};
  --vaults-title-color: #{$dark-theme-vaults-title-color};
  --vaults-component-bg: #{$dark-theme-element-color};
  --vaults-active-component-bg: darken(#{$dark-theme-element-color}, 5);
  --vaults-button-border-color: #{$dark-theme-vaults-farming-color};
  --vaults-ui-bg: #{$dark-theme-secondary-background-color};
  --vaults-tags-bg: #{$dark-theme-vaults-tags-bg};
  --vaults-tag-bg: #{$dark-theme-vaults-tag-bg};
  --vaults-tags-active-color: #{$light-theme-secondary-elements-active-color};
  --vaults-separator-dark: #{$dark-theme-vaults-separator-dark};
  --vaults-button-bg: #{$dark-theme-vaults-button-bg};
  --vaults-button-color: #{$dark-theme-primary-light-text-color};
  --vaults-modal-button-color: #{$dark-theme-primary-light-text-color};
  --vaults-button-color-hover: #{$dark-theme-primary-light-text-color};
  --vaults-selected-color: #{$dark-theme-primary-light-text-color};
  --vaults-border-active: 1px solid #{$dark-theme-primary-light-text-color};
  --vaults-button-color-active: #{$dark-theme-vaults-button-active-text-color};
  --vaults-button-active-bg: darken(#{$dark-theme-vaults-farming-color}, 8);
  --vaults-badge-color-success:  #{$dark-theme-vaults-badge-color-success};
  --vaults-badge-color-fail:  #{$dark-theme-vaults-badge-color-fail};
  --vaults-trade-card-button-disabled-color:  #{$dark-theme-vaults-trade-color-bg};
  --vaults-paginator-active-item-color:  #{$dark-theme-paginator-active-item-color};
  --vaults-paginator-active-item-bg:  #{$dark-theme-paginator-active-item-bg};
  --vaults-trade-card-currency-color: #{$dark-theme-primary-dark-text-color};
  --vaults-chart-border: 2px solid #{$dark-theme-vaults-chart-border-color};
  --vaults-chart-color: #{$dark-theme-primary-dark-text-color};
  --vaults-trade-pending-shadow: rgba(0, 0, 0, 0.5);
  --vaults-item-bg: #{$dark-theme-element-color};
  --vaults-deposit-color: #{$dark-theme-vault-deposit-color};
  --vaults-withdraw-color: #{$dark-theme-vaults-withdraw-color};
  --vaults-header-text-color: #{$light-theme-primary-header-text-color};
  --vaults-trade-card-border: 1px solid #{$light-theme-vaults-trade-border-color};
  --vaults-trade-card-error-bg: #{$light-theme-primary-negative-color};
  --vaults-trade-card-border-error: #{$light-theme-primary-negative-color};
  --vaults-trade-card-bg: #{$dark-theme-vaults-trade-color-bg};
  --vaults-card-color: #{$dark-theme-primary-dark-text-color};
  --vaults-trade-button-move-bg: #{$dark-theme-vaults-trade-button-move-bg};
  --vaults-label-color: #{$dark-theme-primary-dark-text-color};
  --vaults-protocol-bg: #{$dark-theme-primary-elements-light-color};
  --vaults-tooltip-bg: #{$light-theme-vaults-tooltip-bg};
  --vaults-farming-bg: #{$dark-theme-vaults-farming-bg};
  --vaults-farming: #{$light-theme-vaults-farming-color};
  --vaults-farming-disabled-color: #{$dark-theme-vaults-farming-disabled-color};
  --vaults-tooltip-color: #{$dark-theme-vaults-tooltip-color};
  --vaults-input-bg: #{$dark-theme-vaults-input-bg};
  --vaults-farming-disabled-currency-color: #{dark-theme-vaults-farming-disabled-currency-color};

  // settings
  --kyc-form-divider-border-color: #{$light-theme-exchange-divider-border-color};

  // tabs
  --inactive-tab-color: #{$light-theme-exchange-inactive-tab-color};
  --inactive-tab-bg-color: #{$dark-theme-secondary-background-color};
  --active-tab-color: #{$dark-theme-primary-light-text-color};
  --active-tab-bg-color: #{$dark-theme-tabs-bg};
  --active-order-tab-bg-color: #{$dark-theme-order-tabs-bg};
  --active-tab-asset-color: #{$dark-theme-element-color};
  --active-darken-order-tab-bg-color: $dark-theme-thirdy-background-color;
  --active-instrument-bg-color: rgba(0, 0, 0, .1);

  // aside
  --aside-background-color: #{$light-theme-secondary-aside-bg};
  --aside-link-inactive: #{$light-theme-primary-aside-menu-inactive};
  --aside-link-active: #{$light-theme-primary-aside-menu-active};
  --aside-link-active-background: #{$light-theme-primary-aside-menu-active-background};
  --aside-hamburger-background-color: #{$light-theme-secondary-inactive-elements-color};
  --aside-hamburger-color: #{$light-theme-primary-aside-menu-active};

  // button
  --button-primary-color: #{$light-theme-primary-elements-active-text-color};
  --button-primary-background-color: #{$dark-theme-forty-background-color};
  --button-select-primary-background-color: #{$dark-theme-forty-background-color};
  --button-primary-border-radius: 4px;
  --button-primary-border: 1px solid #{$light-theme-primary-elements-dark-color};
  --button-primary-font-size: 18px;
  --button-secondary-color: #{$light-theme-primary-elements-color};
  --button-secondary-background-color: #{$light-theme-secondary-inactive-elements-color};
  --button-secondary-border-radius: 5px;
  --button-secondary-border: 1px solid #{$light-theme-primary-elements-color};
  --button-secondary-font-size: 18px;
  --button-thirdy-background-color: #{$light-theme-primary-elements-dark-color};
  --button-thirdy-background-color-hover: #{$light-theme-secondary-elements-color};
  --button-csv-background-color: #{$dark-theme-forty-background-color};
  --button-csv-color: #{$light-theme-primary-elements-active-text-color};
  --button-settings-nav-bg: #{$dark-theme-secondary-background-color};

  // lang
  --lang-color: #{$light-theme-secondary-elements-active-color};
  --lang-dropdown-bg: #{$light-theme-primary-elements-bg-color};

  //input
  --input-background-color: #{$dark-theme-secondary-background-color};
  --input-background-color-secondary: #{$dark-theme-secondary-background-color};
  --input-background-color-transpatent: #{$dark-theme-secondary-background-color};

  // text-input
  --text-input-primary-font-size: 16px;
  --text-input-primary-border-radius: 5px;
  --text-input-primary-color: #{$light-theme-secondary-regular-text-color};
  --text-input-primary-outline-color: #{$light-theme-primary-dark-text-color};
  --text-input-primary-border: 1px solid #{$light-theme-primary-elements-color};
  --text-input-search-bg: #{$dark-theme-thirdy-background-color};

  // text
  --title-primary-size: 18px;
  --text-error-color: #{$light-theme-primary-negative-color};
  --text-success-color: #{$light-theme-primary-positive-color};
  --title-primary-color: #{$light-theme-secondary-regular-text-color};
  --header-primary-color: #{$light-theme-secondary-regular-text-color};
  --header-primary-color-dark: #{$dark-theme-primary-dark-text-color};
  --text-primary-color: #{$dark-theme-primary-light-text-color};
  --text-primary-color-dark: #{$light-theme-primary-dark-text-color};
  --text-color-title: #{$light-theme-trade-main-button-bg};
  --text-color-secondary: #{$dark-theme-primary-dark-text-color};
  --text-color-secondary-hover: #{$light-theme-primary-regular-text-color};
  --text-auth-links-color: #{$dark-theme-auth-link-text-color};
  --text-auth-links-border-color: #{$dark-theme-auth-link-border-color};

  // modal
  --modal-verification-icon-bg: #{$icon-bg-color-dark};

  // subsum
  --sumsumb-primary-color: #{$light-theme-secondary-regular-text-color};
  --sumsub-success-color: #{$light-theme-primary-positive-color};
  --sumsum-secondary-color: #{$light-theme-secondary-regular-text-color};

  // maintenance
  --maintenance-bg: #{$dark-theme-thirdy-background-color};
  --maintenance-color: #{$dark-theme-primary-light-text-color};

  // component
  --box-shadow: 2px 2px 3px -1px #{$box-shadow-component-color-dark};

  // controls
  --secondary-elemenents-bg-color: #{$light-theme-primary-secondary-bg-color};

  // transition
  --transition: all .3s ease;

  // icon wrapper
  --icon-wrapper-bg: #{$light-theme-primary-elements-active-text-color};
  --icon-wrapper-padding: 5px;
  --icon-wrapper-border-radius: 5px;

  // user menu
  --user-menu-bg: #{$light-theme-primary-elements-bg-color};
  --user-upload-button-bg-color: #{$dark-theme-forty-background-color};
  --user-upload-button-border-color: #{$light-theme-primary-elements-bg-color};
  --user-image-preview-border-color: #{$light-theme-primary-elements-bg-color};

  // kyc pop-up modal
  --kyc-popup-modal-bg: #{$dark-theme-thirdy-background-color};
  --kyc-secondary-button-color: #{$light-theme-primary-elements-active-text-color};

  --overlay-loader-bg: rgba(33, 36, 48, 0.8);

  // elements
  --primary-elements-color: #{$dark-theme-primary-elements-light-color};
  --primary-elements-active-color: #{$dark-theme-primary-elements-active-color};

  //kyc widget
  --kyc-account-select-background-color: #2e3141;
  --kyc-tab-color: #{$dark-theme-primary-light-text-color};

  // quick buy/sell packages
  --buy-sell-circle-background-color: #{$dark-theme-element-color};
  --buy-sell-border-background-color: #{$dark-theme-vaults-farming-color};
  --buy-sell-color: #{$dark-theme-primary-elements-light-color};
  --buy-sell-trade-main-color: #{$dark-theme-primary-elements-light-color};

  //login / sign up
  --input-bg-color:  #{$light-theme-primary-elements-bg-color};

  --scrollbar-track-bg: #{$light-theme-secondary-background-color};
  --scrollbar-bg: #{$dark-theme-secondary-background-color};

  // currencies colors
  --currencies-colors-bch: #3ff71a;
  --currencies-colors-btc: #F7931A;
  --currencies-colors-usd: #2775CA;
  --currencies-colors-usdc: #2775CA;
  --currencies-colors-usdt: #167a42;
  --currencies-colors-dai: #FAB62C;
  --currencies-colors-eth: #627EEA;
  --currencies-colors-xrp: #23292F;

  // chart
  --chart-tooltip-bg: #{$dark-theme-primary-elements-bg-color};
  --chart-default-color: #{$light-theme-primary-elements-dark-color};
  --chart-default-color-border: #{$dark-theme-primary-elements-bg-color};
  --chart-portfolio-tooltip-bg: #{$dark-theme-fifth-background-color};

  --text-negative: #{$light-theme-primary-negative-text-color};

  --backdrop-color: rgba(78, 76, 76, 0.6);

  // ecp tradu
  --tradu-text-color : #ffffff;
  --tradu-input-bg-color : #262626;
  --tradu-button-text-color: #000000;
  --tradu-button-bg-color: #FF6101;
  --tradu-button-bg-disabled-color: #4D4D4D;
  --tradu-button-disabled-color: #B3B3B3;
  --tradu-button-hover-background-color: #fd7019;
  --tradu-button-hover-color: #4d4d4d;
  --tradu-button-border: 1px #4d4d4d solid;
  --tradu-diclaimer-body-color: #b3b3b3;
}
