@import './variables';

@mixin useFlex($align: center, $justify: center, $direction: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $direction;
  flex-wrap: wrap;
}

@mixin useGrid(
  $grid-template-columns: 1fr 1fr 1fr,
  $grid-gap: $default-space,
  $grid-template-areas: 'first',
  $grid-template-rows: 1fr,
) {
  display: grid;
  grid-gap: $grid-gap;
  grid-template-columns: $grid-template-columns;
  grid-template-areas: $grid-template-areas;
  grid-template-rows: $grid-template-rows;
}

@mixin useIconWrapper(
  $padding: var(--icon-wrapper-padding, 5px),
  $border-radius: var(--icon-wrapper-border-radius, 5px),
  $background-color: var(--icon-wrapper-bg),
) {
  padding: $padding;
  border-radius: $border-radius;
  background-color: $background-color;
}

@mixin marketsExchangeList() {
  margin-bottom: $small-space;
  padding: 2px 0;

  & > * {
    flex: 1;

    &:first-child {
      min-width: 40%;
    }
    &:last-child {
      margin-bottom: 0;
      text-align: right;
    }
  }
}

@mixin defaultMarginInList() {
  & > * {
    margin: 0 auto calc(#{$small-space} * 2) auto;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@mixin inputStyle {
  border-radius: var(--text-input-primary-border-radius, 5px);
  width: 100%;
  font-weight: normal;
  padding: $small-space;
  margin-bottom: $default-space;
  border: var(--text-input-primary-border);
  color: var(--text-input-primary-color);
  outline-color: var(--text-input-primary-outline-color);
  outline-width: 2px;
  background-color: var(--input-background-color);

  &[placeholder="0"] {
    &::placeholder {
      font-style: normal;
    }
  }

  &::placeholder {
    font-style: italic;
  }
}

@mixin useInput() {
  &.primary input {
    border-radius: var(--text-input-primary-border-radius, 5px);
    font-size: var(--text-input-primary-font-size, 18px);
  }

  &.secondary input {
    border-radius: var(--text-input-secondary-border-radius, 5px);
    font-size: var(--text-input-secondary-font-size, 18px);
  }

  input {
    @include inputStyle;

    &.nova-input-small {
      padding: $small-space;
    }

    &.nova-input-medium {
      padding: $default-space;
    }

    &.nova-with-border-radius.nova-input-medium {
      border-radius: $default-space;
    }

    &.nova-with-border-radius.nova-input-small {
      border-radius: $small-space;
    }

    &::-webkit-input-placeholder {
      color: var(--text-input-primary-color);
    }
    &:-ms-input-placeholder {
      color: var(--text-input-primary-color);
    }
    &::placeholder {
      color: var(--text-input-primary-color);
    }
  }
}

/* Currencies */
$currencies: btc, usd, usdc, dai, wbtc, eth, weth, gyen, zusd, usdt, aave, brz, bilira, tryb, xrp, alkemi, xlm, hbar, euroc;
@mixin currenciesColors($property: 'background-color') {
  @for $i from 1 through length($currencies) {
    $currency: nth($currencies, $i);
    &.currency-#{$currency} {
      #{$property}: var(--vaults-currencies-colors-#{$currency});
    }
  }
}
@mixin vaultsCurrenciesBoxShadows($value, $transparency) {
  @for $i from 1 through length($currencies) {
    $currency: nth($currencies, $i);
    &.box-shadow-currency-#{$currency} {
      box-shadow: #{$value} rgba(var(--vaults-currencies-colors-rgb-#{$currency}), $transparency);
    }
  }
}
@mixin vaultsCurrenciesBgColors {
  @include vaultsCurrenciesColors;
}
@mixin vaultsCurrenciesBorderColors {
  @include vaultsCurrenciesColors('border-color');
}
@mixin vaultsCurrenciesTextColors {
  @include vaultsCurrenciesColors('color');
}
@mixin vaultsCurrenciesSvgColors {
  @include vaultsCurrenciesColors('fill');
  @include vaultsCurrenciesColors('stroke');
}
@mixin vaultsCurrenciesSvgFill {
  @include vaultsCurrenciesColors('fill');
}

@mixin currenciesSvgFill {
  @include currenciesColors('fill');
}

.currency-dot {
  @include currenciesColors;
}

@mixin fieldLabel {
  font-weight: 500;
  font-size: 14px;
  color: var(--text-input-primary-color, $light-theme-secondary-regular-text-color);
  margin-bottom: 5px;
}

@mixin depositWithdrawModalVisibility {
  max-height: $wallet-deposit-withdraw-max-height-modal;
  overflow-y: visible;
  overflow-x: visible;

  @media (max-height: 700px) {
    overflow-y: auto;
    padding-left: 1px;
    overflow-x: hidden;
    padding-right: $small-space;

    &.no-info {
      padding-right: 0;
    }
  }
}
