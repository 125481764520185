@import './variables.scss';

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-track-bg, $dark-theme-thirdy-background-color);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background-color: var(--scrollbar-bg, $dark-theme-secondary-background-color);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-track-bg, $light-theme-secondary-background-color);
  border-radius: 3px;
  border: 1.5px solid var(--scrollbar-bg, $dark-theme-secondary-background-color);
}

*::-webkit-scrollbar-track-piece {}
