@import '/public/vendor/fontawesome/fontawesome';
@import '/public/vendor/fontawesome/solid';
@import '/public/vendor/fontawesome/regular';
@import '/public/vendor/fontawesome/light';
@import '/public/vendor/fontawesome/brands';

@import './reset.scss';
@import './variables';
@import './mixins';
@import './scrollbar.scss';
@import './fonts/fonts.scss';

@keyframes animateOpacity {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

.opacity-animate {
  animation: animateOpacity infinite;
  transition: all 0.2s ease;
  animation-duration: 2s;
}

.danger-icon {
  color: $light-theme-primary-negative-text-color;
}

* {
  overscroll-behavior: contain;
  // font-family: "Nunito Sans", sans-serif;
}

.corporate-form-widget-container {
  width: 100% !important;
}

.session-wrapper.tradu{
  font-family: Sohne;
}
